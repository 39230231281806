<template>
  <div class="flex">
    <h2 class="font-medium text-gray-700 text-center text-lg">Saldos</h2>
  </div>
  <div
    id="grafica"
    ref="elGrafica"
    style="height:275px;"
    class="w-full"
  >
  </div>

</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import currency from 'currency.js'

export default {
  props: {
    totalCancelado: {
      type: Number,
      required: true,
      default: null
    },
    totalSaldo: {
      type: Number,
      required: true,
      default: null
    },
    productId: {
      type: Number,
      required: true,
      default: null
    }
  },
  setup (props, context) {
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const colorLabel = ref('white')
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    const elGrafica = ref(null)
    let chart = reactive({})

    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const colorPalette = ['#04BF45', '#c31776', '#F29F05', '#12a0d7']
      const option = {
        tooltip: {
          formatter (a) {
            return `${a.name} <br> ${currency(a.value, { precision: 0, separator: '.' }).format()}  <br> ${a.percent}%`
          }
        },
        legend: {
          orient: 'vertical',
          left: 15,
          bottom: 15
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            saveAsImage: {}
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '45%'],
            color: colorPalette,
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 9,
              borderColor: '#fff',
              borderWidth: 2
            },
            labelLine: {
              length: 20
            },
            label: {
              fontSize: 11,
              formatter (a) {
                return `${a.data.name}  \n ${currency(a.value, { precision: 0, separator: '.' }).format()} \n ${a.percent}%`
              }
            },
            data: [
              { value: props.totalCancelado, name: 'Total Saldo Pagado' },
              { value: props.totalSaldo, name: 'Total Saldo Pendiente' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      chart.setOption(option)
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', evt => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        series: [{
          data: [
            { value: props.totalCancelado, name: 'Total Saldo Pagado' },
            { value: props.totalSaldo, name: 'Total Saldo Pendiente' }
          ]
        }]
      }

      chart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      elGrafica
    }
  }
}
</script>

<style>

</style>
